import React, { Component } from "react";
import styled from "styled-components";
import Layout from "../layout/Layout";

const TileContainer = styled.div`
    padding: 0 1.5rem;
    position: absolute;
    left: 0;
    bottom: 3rem;

    ${({theme}) => theme.media.desktop} {
        padding: 0 3.5rem;
    }
`;

const TileContent = styled.h2`
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: ${({theme}) => theme.colors.black};
`;

const TileSubContent = styled.p`
    color: ${({theme}) => theme.colors.black};
`;

const TestWrapper = styled.div`
    display: flex;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    min-height: 100vh;
    position: fixed;
`;

const TestTileImage = styled.div`
    min-height: 100vh;
    position: relative;
    background: #fff;
    width: 75%;
    display: none;

    ${({theme}) => theme.media.desktop} {
        display: flex;
        width: 75%;
    }
`;

const TestTileContent = styled.div`
    min-height: 100vh;
    width: 100%;
    position: relative;
    background: ${({theme}) => theme.colors.white};

    ${({theme}) => theme.media.desktop} {
        display: flex;
        width: 25%;
    }
`;


class Test extends Component {
   render() {
      return (
         <Layout>
            <TestWrapper>
               <TestTileImage>
               </TestTileImage>
               <TestTileContent>
                  <TileContainer>
                     <TileContent>Coś tam coś tam na wsi</TileContent>
                     <TileSubContent>
                     Bob budowniczy wszystko spierniczy
                     </TileSubContent>
                     <TileSubContent>
                        JEDEN Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
                        JEDEN Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
                        JEDEN Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
                        JEDEN Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
                        JEDEN Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
                     </TileSubContent>
                  </TileContainer>
               </TestTileContent>
            </TestWrapper>
         </Layout>
      )
   }
}

export default Test;